.panel-cupones-detalle-coleccion {
  width: 100%;
  min-height: 100vh;
  padding: 40px 10px 10px 10px;
}

.panel-cupones-detalle-coleccion .regresar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  color: gray;
  transition: all 0.3s;
  cursor: pointer;
}

.panel-cupones-detalle-coleccion .regresar:hover {
  color: rgb(0, 115, 29);
  text-decoration: underline;
}

.panel-cupones-detalle-coleccion .div-info-coleccion {
  width: 100%;
  padding: 0 40px 20px 40px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  box-sizing: border-box;
}

.panel-cupones-detalle-coleccion
  .div-info-coleccion
  .div-botones-editar-coleccion {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.panel-cupones-detalle-coleccion
  .div-info-coleccion
  .div-botones-editar-coleccion button{
    background-color: rgb(0, 71, 18);
    border: none;
    border-radius: 8px;
    color: white;
    padding: 12px;
    margin: 0 5px;
    font-size: 14px;
    transition: all 0.4s;
    cursor: pointer;
}

.panel-cupones-detalle-coleccion
  .div-info-coleccion
  .div-botones-editar-coleccion button:hover{
    background-color: rgb(136, 109, 0);
}

.panel-cupones-detalle-coleccion
  .div-info-coleccion
  .div-input-nombre-coleccion,
.panel-cupones-detalle-coleccion
  .div-info-coleccion
  .div-input-descripcion-coleccion {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
}

.panel-cupones-detalle-coleccion
  .div-info-coleccion
  .div-input-nombre-coleccion
  label,
.panel-cupones-detalle-coleccion
  .div-info-coleccion
  .div-input-descripcion-coleccion
  label {
  font-size: 18px;
  font-weight: 600;
  color: dimgray;
}

.panel-cupones-detalle-coleccion
  .div-info-coleccion
  .div-input-nombre-coleccion
  input:disabled {
  font-size: 24px;
  color: rgb(53, 53, 53);
  border: none;
  background-color: transparent;
  transition: all 0.5;
  width: 100%;
  border: 1px solid transparent;
}

.panel-cupones-detalle-coleccion
  .div-info-coleccion
  .div-input-nombre-coleccion
  input {
  font-size: 24px;
  color: rgb(0, 0, 0);
  border: none;
  background-color: rgb(255, 255, 255);
  transition: all 0.5;
  width: 100%;
  border: 1px solid gray;
}

.panel-cupones-detalle-coleccion
  .div-info-coleccion
  .div-input-descripcion-coleccion
  textarea:disabled {
  font-size: 20px;
  color: rgb(53, 53, 53);
  width: 100%;
  height: 85px;
  resize: none;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  border: none;
  background-color: white;
  border-radius: 12px;
  box-shadow: inset 0px 0px 14px 2px #d6d6d6;
  padding: 10px;
  box-sizing: border-box;
  transition: all 0.5;
  border: 1px solid transparent;
}

.panel-cupones-detalle-coleccion
  .div-info-coleccion
  .div-input-descripcion-coleccion
  textarea {
  font-size: 20px;
  color: rgb(0, 0, 0);
  width: 100%;
  height: 85px;
  resize: none;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  border: none;
  background-color: white;
  border-radius: 12px;
  box-shadow: inset 0px 0px 14px 2px #ffffff;
  padding: 10px;
  box-sizing: border-box;
  transition: all 0.5;
  border: 1px solid gray;
}

.panel-cupones-detalle-coleccion .div-cupones-coleccion {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 12px;
  /* box-shadow: inset 0px 0px 14px 2px #d6d6d6; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.panel-cupones-detalle-coleccion
  .div-cupones-coleccion
  .div-tabla-cupones-asociados {
  height: 50vh;
  width: 100%;
}

.panel-cupones-detalle-coleccion .div-cupones-coleccion .div-sin-cupones p {
  font-size: 18px;
  font-weight: 600;
  color: dimgray;
}

.panel-cupones-detalle-coleccion
  .div-cupones-coleccion
  .div-sin-cupones
  .div-botones-sin-cupones {
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel-cupones-detalle-coleccion
  .div-cupones-coleccion
  .div-sin-cupones
  .div-botones-sin-cupones
  button {
  background-color: rgb(230, 242, 248);
  border: none;
  border-radius: 12px;
  box-shadow: inset 0px 0px 14px 2px #e2e2e2;
  padding: 10px 15px;
  margin: 10px;
  cursor: pointer;
  transition: all 0.5s;
}

.panel-cupones-detalle-coleccion
  .div-cupones-coleccion
  .div-sin-cupones
  .div-botones-sin-cupones
  button:hover {
  background-color: rgb(215, 242, 255);
}

.panel-cupones-detalle-coleccion
  .div-cupones-coleccion
  .div-sin-cupones
  .div-botones-sin-cupones
  button
  svg {
  font-size: 40px;
}

.panel-cupones-detalle-coleccion .div-asociar-cupon {
  background-color: rgba(255, 255, 255, 0.466);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0px #b9b9b9;
  overflow: auto;
  max-height: 80vh;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.panel-cupones-detalle-coleccion .div-asociar-cupon .div-tabla {
  height: 70vh;
  max-width: 98vw;
}

.panel-cupones-detalle-coleccion
  .div-asociar-cupon
  .boton-cancelar-asociar-cupon {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  font-size: 18px;
  transition: all 0.4s;
  cursor: pointer;
  width: 110px;
}

.panel-cupones-detalle-coleccion
  .div-asociar-cupon
  .boton-cancelar-asociar-cupon:hover {
  background-color: rgb(136, 109, 0);
}
