.panel-cupones {
  padding: 16px;
  max-height: 100vh;
  width: 100%;
}

.panel-cupones .div-tabs-cupones {
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-bottom: 16px;
  height: 29.6px;
}

.panel-cupones .div-tabs-cupones p {
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 20px;
  transition: all 0.3s;
}

.panel-cupones .div-tabs-cupones .tab-cupones-activo {
  text-decoration: underline;
  color: #eb7d00;
  cursor: auto;
}

.panel-cupones .div-tabs-cupones .tab-cupones-inactivo {
  color: #663700;
}

.panel-cupones .div-tabs-cupones .tab-cupones-inactivo:hover {
  color: #f78400;
}

.panel-cupones .div-tabla-dinamica-cupones{
  height: calc(100% - 46px);
}

.botones-columna-acciones-cupones,
.botones-columna-acciones-cupones-inactivos {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #00731d;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s;
}

.botones-columna-acciones-cupones-inactivos {
  color: #b9b9b9;
}

.botones-columna-acciones-cupones:hover,
.botones-columna-acciones-cupones-inactivos:hover {
  color: rgb(136, 109, 0);
}
