.contenedor-general-tabla-dinamica {
  background-color: white;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0px 0px 13px -4px darkgrey;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
}

.div-tabla-dinamica {
  width: 100%;
  height: calc(100% - 46px);
  overflow: auto;
}

.tabla-dinamica {
  border-spacing: 0;
  border-collapse: collapse;
  min-width: 100%;
}

.tabla-dinamica th {
  position: sticky;
  top: 0;
  color: rgba(102, 55, 0, 0.85);
  background-color: rgb(253, 248, 242);
  border: rgba(107, 57, 0, 0.3) solid 1px;
  font-size: 14px;
  padding: 3px 5px;
}

.tabla-dinamica td {
  border: rgba(107, 57, 0, 0.3) solid 1px;
  color: rgba(102, 55, 0, 0.85);
  font-size: 14px;
  white-space: nowrap;
  word-break: keep-all;
  padding: 3px 5px;
  font-weight: 800;
  font-weight: 500;
  border-radius: 0px;
}

.tabla-dinamica .seleccionable:hover td {
  background: rgb(230, 230, 230);
  cursor: pointer;
}

.barra-acciones-tabla-dinamica {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  height: fit-content;
  padding: 0 5px 16px 5px;
  box-sizing: border-box;
  font-size: 16px;
  overflow: hidden;
}

.columna-check-tabla-dinamica {
  position: sticky;
  left: 0;
  background-color: rgb(253, 248, 242);
}

th.columna-check-tabla-dinamica {
  z-index: 1;
}

.columna-acciones-tabla-dinamica {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabla-dinamica-botones-crud {
  display: flex;
  align-items: center;
  height: 34px;
  background-color: rgb(0, 115, 29);
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 8px 12px;
}

.tabla-dinamica-botones-crud svg {
  margin-right: 6px;
  font-size: 16px;
}

.buscador-tabla-dinamica {
  display: flex;
  align-items: center;
}

.buscador-tabla-dinamica svg {
  background-color: rgb(0, 115, 29);
  color: white;
  padding: 0 5px 0 10px;
  height: 34px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 18px;
}

.buscador-tabla-dinamica input {
  border: rgb(0, 115, 29) solid 1px;
  height: 30px;
}

.buscador-tabla-dinamica select {
  background-color: rgb(0, 115, 29);
  color: white;
  height: 34px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: none;
}

.barra-acciones-tabla-dinamica button,
.barra-acciones-tabla-dinamica select {
  transition: all 0.3s;
  cursor: pointer;
}

.barra-acciones-tabla-dinamica button:hover,
.barra-acciones-tabla-dinamica select:hover {
  background-color: rgb(217, 173, 0);
}

.barra-acciones-tabla-dinamica button:active,
.barra-acciones-tabla-dinamica select:active {
  background-color: rgb(209, 112, 0);
}
