.barra-lateral {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 222px;
  min-height: 100vh;
  border-right: #c7c7c7 solid 1px;
  box-shadow: 1px 0px 16px -9px;
  z-index: 1;
  background-color: white;
}

.boton-desplegar-barra-lateral {
  position: relative;
  z-index: 1;
  top: 0;
  left: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  background-color: rgb(255, 255, 255);
  color: rgb(138, 138, 138);
  box-shadow: 9px 0px 20px 0px #cacaca66;
}

.header-barra-lateral {
  padding: 10px 16px;
  border-bottom: #c7c7c7 solid 1px;
}

.header-barra-lateral img {
  max-width: 190px;
}

.header-barra-lateral p {
  color: #4e4944;
  font-weight: 600;
}

.opciones-barra-lateral {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 20px);
  padding: 10px;
  color: #a2988e;
  font-weight: 600;
  transition: all 0.3s;
  text-decoration: none;
}

.opciones-barra-lateral-seleccionada {
  color: #4e4944;
  background-color: rgb(248 248 248 / 37%);
}

.opciones-barra-lateral:hover {
  color: #4e4944;
  background-color: rgb(248 248 248 / 37%);
}

.opciones-barra-lateral svg {
  margin: 0 10px;
}

@media screen and (max-width: 950px) {
  .barra-lateral {
    position: fixed;
    z-index: 2;
    left: -222px;
  }
}

@media screen and (min-width: 951px) {
  .boton-desplegar-barra-lateral {
    display: none;
  }
}
