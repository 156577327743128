.modalConfirmacionEnvioResumen {
  background-color: rgb(255, 255, 255);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0px #b9b9b9;
  font-size: 16px;
  font-weight: 700;
}

.modalConfirmacionEnvioResumen .botonNo,
.modalConfirmacionEnvioResumen .bottonSi {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  transition: all 0.4s;
}

.modalConfirmacionEnvioResumen .botonNo:hover,
.modalConfirmacionEnvioResumen .bottonSi:hover {
  background-color: rgb(136, 109, 0);
}

.modalConfirmacionEnvioResumen .botonNo:active,
.modalConfirmacionEnvioResumen .bottonSi:active {
  background-color: rgb(0, 71, 18);
}
