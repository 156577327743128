#visualizadorDeReservacion {
  display: grid;
  grid-template-areas:
    "e e e"
    "r r u"
    "c b u"
    "x x x";
  background-color: rgba(255, 255, 255, 0.466);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0px #b9b9b9;
  overflow: auto;
  max-height: 80vh;
}

#visualizadorDeReservacion .bloque {
  text-align: left;
  padding: 16px;
}

#visualizadorDeReservacion .encabezado {
  grid-area: e;
}

#visualizadorDeReservacion .encabezado.confirmada h2 {
  color: rgb(0, 99, 25);
}

#visualizadorDeReservacion .encabezado.cancelada h2 {
  color: rgb(116, 0, 0);
}

#visualizadorDeReservacion .datosCliente {
  grid-area: c;
}

#visualizadorDeReservacion .datosReservacion {
  grid-area: r;
}

#visualizadorDeReservacion .datosBalance {
  grid-area: b;
}

#visualizadorDeReservacion .usuario {
  grid-area: u;
  padding: 16px;
}

#visualizadorDeReservacion .usuario img {
  width: 150px;
  clip-path: circle(50%);
}

#visualizadorDeReservacion .divBotones {
  grid-area: x;
  display: flex;
  justify-content: center;
  width: 100%;
}

#visualizadorDeReservacion .botonCerrar {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.4s;
}

#visualizadorDeReservacion .botonCerrar:hover {
  background-color: rgb(136, 109, 0);
}

#visualizadorDeReservacion .botonCerrar:active {
  background-color: rgb(0, 71, 18);
}

@media screen and (max-width: 800px) {
  #visualizadorDeReservacion {
    font-size: 14px;
    grid-template-areas:
      "e e e"
      "r r u"
      "c c b"
      "x x x";
  }

  #visualizadorDeReservacion .usuario img {
    width: 100px;
  }
}
@media screen and (max-width: 500px) {
  #visualizadorDeReservacion {
    font-size: 12px;
    grid-template-areas:
      "e"
      "r"
      "c"
      "b"
      "u"
      "x";
    overflow: auto;
  }

  #visualizadorDeReservacion .usuario img {
    width: 70px;
  }
}
