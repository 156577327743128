.formulario-ingreso {
  width: 280px;
  display: flex;
  flex-flow: column;
  background-color: white;
  box-shadow: 0px 0px 14px 0px grey;
  padding: 12px;
  border-radius: 8px;
}

.formulario-ingreso-cuentas,
.formulario-ingreso-fuentes,
.formulario-ingreso .botones {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.formulario-ingreso-cuenta,
.formulario-ingreso-fuente {
  box-shadow: 0px 0px 5px 0px grey;
  padding: 8px;
  margin: 3px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.4s;
}

.formulario-ingreso-cuenta.seleccionada,
.formulario-ingreso-fuente.seleccionada {
  background-color: rgb(235, 255, 235);
}

.formulario-ingreso .botones button {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  transition: all 0.4s;
  cursor: pointer;
}

.formulario-ingreso .botones button:hover {
  background-color: rgb(136, 109, 0);
}

.formulario-ingreso .botones button:active {
  background-color: rgb(0, 71, 18);
}
