#formLigarEmailACupon {
  background-color: rgb(255, 255, 255);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0px #b9b9b9;
  font-size: 16px;
  font-weight: 700;
}

#formLigarEmailACupon .divBotonesLigarEmailACupon {
  display: flex;
  justify-content: center;
  width: 100%;
}

#formLigarEmailACupon .botonCancelar,
#formLigarEmailACupon .botonLigar {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  transition: all 0.4s;
  width: 80px;
}

#formLigarEmailACupon .botonCancelar:hover,
#formLigarEmailACupon .botonLigar:hover {
  background-color: rgb(136, 109, 0);
}

#formLigarEmailACupon .botonCancelar:active,
#formLigarEmailACupon .botonLigar:active {
  background-color: rgb(0, 71, 18);
}
