.boton-nuevo-egreso {
  background-color: rgb(53, 14, 0);
  width: 150px;
  height: 150px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border-radius: 75px;
  color: white;
  font-size: 20px;
  box-shadow: 0px 0px 10px 2px rgb(155 155 155);
  cursor: pointer;
  transition: all 0.3s;
}

.boton-nuevo-egreso:hover {
  background-color: rgb(77, 20, 0);
}

.boton-nuevo-egreso svg {
  font-size: 50px;
}
