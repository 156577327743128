.nueva-coleccion-cupones {
  background-color: rgba(255, 255, 255, 0.466);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0px #b9b9b9;
  overflow: auto;
  max-height: 80vh;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.nueva-coleccion-cupones .div-nombre-descripcion-nueva-coleccion {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.nueva-coleccion-cupones .div-nombre-descripcion-nueva-coleccion input,
.nueva-coleccion-cupones .div-nombre-descripcion-nueva-coleccion textarea {
  resize: none;
  width: 300px;
  max-width: 95vw;
  border-radius: 8px;
  border: 1px solid black;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  padding: 6px;
  font-size: 16px;
  margin: 10px;
}

.nueva-coleccion-cupones .div-botones-nueva-coleccion button {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  font-size: 18px;
  transition: all 0.4s;
  cursor: pointer;
  width: 110px;
}

.nueva-coleccion-cupones .div-botones-nueva-coleccion button:hover {
  background-color: rgb(136, 109, 0);
}

.nueva-coleccion-cupones .div-botones-nueva-coleccion button:active {
  background-color: rgb(0, 71, 18);
}
