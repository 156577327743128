#configuradorDeFechas {
  width: calc(100% - 222px);
  height: 200px;
  background-color: rgb(241, 241, 241);
  position: fixed;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 1px 16px -9px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  /* Estado inicial para animacion */
  opacity: 0;
  top: -200px;
}

#configuradorDeFechas .iconoCerrar {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 28px;
  color: gray;
  cursor: pointer;
  transition: all 0.25s;
}

#configuradorDeFechas .iconoCerrar:hover {
  color: rgb(80, 80, 80);
}

#configuradorDeFechas .iconoCerrar:active {
  color: gray;
}

#configuradorDeFechas .divBotones {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#configuradorDeFechas .divBotones button {
  width: 230px;
  height: 50px;
  margin: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 25px;
  border-radius: 8px;
  border: none;
  background-color: white;
  color: rgb(92, 92, 92);
  cursor: pointer;
  box-shadow: 0px 0px 7px 0px #838383;
  transition: all 0.15s;
}

#configuradorDeFechas .divBotones button:hover {
  box-shadow: 0px 0px 12px 0px #838383;
}

#configuradorDeFechas .divBotones button:active {
  box-shadow: 0px 0px 5px 0px #838383;
}

#configuradorDeFechas .divMinimoDeNoches {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

#configuradorDeFechas .divBotones select {
  width: 230px;
  height: 50px;
  margin: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 25px;
  border-radius: 8px;
  border: none;
  background-color: white;
  color: rgb(92, 92, 92);
  cursor: pointer;
  box-shadow: 0px 0px 7px 0px #838383;
  transition: all 0.15s;
}

@media screen and (max-width: 950px) {
  #configuradorDeFechas {
    width: 100%;
  }
}
