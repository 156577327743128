.formulario-egreso {
  width: 280px;
  display: flex;
  flex-flow: column;
  background-color: white;
  box-shadow: 0px 0px 14px 0px grey;
  padding: 12px;
  border-radius: 8px;
}

.formulario-egreso-cuentas,
.formulario-egreso-categorias,
.formulario-egreso .botones {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.formulario-egreso-cuenta,
.formulario-egreso-categoria,
.formulario-egreso-facturado {
  box-shadow: 0px 0px 5px 0px grey;
  padding: 8px;
  margin: 3px;
  border-radius: 8px;
}

.formulario-egreso-cuenta.seleccionada {
  background-color: rgb(235, 255, 235);
  cursor: pointer;
  transition: all 0.4s;
}

.formulario-egreso .botones button {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  transition: all 0.4s;
  cursor: pointer;
}

.formulario-egreso .botones button:hover {
  background-color: rgb(136, 109, 0);
}

.formulario-egreso .botones button:active {
  background-color: rgb(0, 71, 18);
}
