#editorDeReservacion {
  display: grid;
  grid-template-areas:
    "e e e"
    "r r u"
    "c b u"
    "p p p";
  background-color: rgba(255, 255, 255, 0.466);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0px #b9b9b9;
  overflow: auto;
  max-height: 80vh;
}

#editorDeReservacion .inputLargo,
#editorDeReservacion .inputCorto {
  background-color: rgba(255, 255, 255, 0.164);
  border: none;
  border-bottom: rgb(168, 168, 168) 2px solid;
}

#editorDeReservacion .inputLargo {
  width: 100%;
}

#editorDeReservacion .inputCorto {
  width: 32px;
}

#editorDeReservacion .bloque {
  text-align: left;
  padding: 16px;
}

#editorDeReservacion .encabezado {
  grid-area: e;
}

#editorDeReservacion .encabezado .titulo {
  font-size: 24px;
  font-weight: bold;
}

#editorDeReservacion .encabezado .fecha {
  font-size: 16px;
}

#editorDeReservacion .encabezado select {
  border: none;
  background: none;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

#editorDeReservacion .encabezado.confirmada h2 {
  color: rgb(0, 99, 25);
}

#editorDeReservacion .encabezado.cancelada h2 {
  color: rgb(116, 0, 0);
}

#editorDeReservacion .datosCliente {
  grid-area: c;
}

#editorDeReservacion .datosReservacion {
  grid-area: r;
}

#editorDeReservacion .datosBalance {
  grid-area: b;
}

#editorDeReservacion .usuario {
  grid-area: u;
  padding: 16px;
}

#editorDeReservacion .usuario img {
  width: 150px;
  clip-path: circle(50%);
}

#editorDeReservacion .divBotones {
  grid-area: p;
}

#editorDeReservacion .divBotones button {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  font-size: 18px;
  transition: all 0.4s;
}

#editorDeReservacion .divBotones button:hover {
  background-color: rgb(136, 109, 0);
}

#editorDeReservacion .divBotones button:active {
  background-color: rgb(0, 71, 18);
}

#editorDeReservacion .modalNuevoCosto {
  background-color: rgb(255, 255, 255);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0px #b9b9b9;
  font-size: 16px;
  font-weight: 700;
}

#editorDeReservacion .modalNuevoCosto .cancelar,
#editorDeReservacion .modalNuevoCosto .ok {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  transition: all 0.4s;
}

@media screen and (max-width: 700px) {
  #editorDeReservacion {
    grid-template-areas:
      "e e e"
      "r r r"
      "c c c"
      "b b b"
      "u u u"
      "p p p";
  }
}
