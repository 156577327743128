#visualizadorDeUsuario {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.466);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 #b9b9b9;
  overflow: auto;
  max-height: 80vh;
}

#visualizadorDeUsuario p,
#visualizadorDeUsuario a {
  width: 100%;
  text-align: center;
  color: gray;
  font-weight: 500;
  margin: 5px 0;
}

#visualizadorDeUsuario button {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  font-size: 18px;
  transition: all 0.4s;
  cursor: pointer;
}

#visualizadorDeUsuario button:hover {
  background-color: rgb(136, 109, 0);
}

#visualizadorDeUsuario button:active {
  background-color: rgb(0, 71, 18);
}
