.modal-reservaciones {
  background-color: rgb(252 255 242);
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0px 0px 12px 0px #cecece;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.cuerpo-modal-resevaciones {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.modal-reservaciones label {
  color: rgba(102, 55, 0, 0.85);
  font-size: 14px;
  white-space: nowrap;
  word-break: keep-all;
  padding: 3px 5px;
  font-weight: 500;
  text-align: left;
}

.modal-reservaciones input {
  width: 100%;
}

.modal-reservaciones select {
  min-width: 150px;
}

.modal-reservaciones select,
.modal-reservaciones input {
  border: #00731d 1px solid;
  border-radius: 5px;
  padding: 5px 2px;
  font-size: 14px;
  white-space: nowrap;
  word-break: keep-all;
  font-weight: 600;
  color: gray;
}

.div-nombre-modal-reservaciones {
  width: 100%;
}

.div-contacto-modal-reservaciones {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.div-telefono-modal-reservaciones,
.div-correo-modal-reservaciones {
  width: 48%;
  display: flex;
  flex-flow: column;
}

.divs-huespedes-modal-reservaciones {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 5px 0;
}

.divs-huespedes-modal-reservaciones input {
  width: 40px;
  text-align: center;
}
