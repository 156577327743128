.panel-usuarios {
  padding: 16px;
  max-height: 100vh;
  width: 100%;
}

.botones-columna-acciones-usuarios {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #00731d;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s;
}

.botones-columna-acciones-usuarios:hover {
  color: rgb(136, 109, 0);
}
