#editorDeUsuario {
  background-color: hsla(0, 0%, 100%, 0.466);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 #b9b9b9;
  overflow: auto;
  max-height: 80vh;
}

#editorDeUsuario form {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}

#editorDeUsuario form {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}

#editorDeUsuario form label {
  width: 100%;
  text-align: left;
  color: gray;
  font-weight: 500;
}

#editorDeUsuario form input {
  width: 100%;
  margin-bottom: 15px;
}

#editorDeUsuario form select {
  width: 100%;
  margin-bottom: 15px;
}

#editorDeUsuario form .divBotones {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

#editorDeUsuario form .divBotones button {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  font-size: 18px;
  transition: all 0.4s;
  cursor: pointer;
}

#editorDeUsuario .divBotones button:hover {
  background-color: rgb(136, 109, 0);
}

#editorDeUsuario .divBotones button:active {
  background-color: rgb(0, 71, 18);
}
