#calendarios {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 16px;
}

.visualizacionDeFechas {
  display: flex;
  width: 100%;
}

.divBotonesVisualizacionFechas {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 24px;
  color: gray;
}

.etiquetasFechas {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60px;
  border-right: rgba(128, 128, 128, 0) solid 1px;
  border-left: rgba(128, 128, 128, 0) solid 1px;
  font-size: 14px;
  font-weight: 700;
  color: gray;
  user-select: none;
}

.etiquetasFechas.Alta {
  color: rgb(199, 113, 0);
  background-color: rgb(255, 237, 214);
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
}

.etiquetasFechas.Dias.festivos {
  color: rgb(145, 0, 0);
  background-color: rgb(253, 213, 213);
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
}

.diaDeCalendario {
  width: 100%;
  height: 60px;
  border: gray solid 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: relative;
}

.diaDeCalendario .background {
  height: 100%;
  width: 100%;
  z-index: 0;
  position: absolute;
  cursor: pointer;
  transition: all 0.5s;
}

.diaDeCalendario.fechaPasada {
  background-color: rgb(214, 214, 214);
}

.fechaSobrereserva p{
  background-color: rgb(252, 228, 150);
  font-weight: 700;
}

.directasFuenteConfirmada p,
.directasFuentePagopendiente p,
.directasFuenteConcluida p,
.directasFuenteTemporal p,
.airbnbFuente p,
.fechaSobrereserva p {
  width: 100%;
  min-height: 20px;
  margin: 0;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.directasFuenteConfirmada p {
  background-color: rgb(179, 223, 171, 0.6);
}
.directasFuenteConfirmada p:hover {
  background-color: rgb(179, 223, 171);
}

.directasFuentePagopendiente p {
  background-color: rgba(129, 175, 218, 0.6);
}
.directasFuentePagopendiente p:hover {
  background-color: rgb(129, 175, 218);
}

.directasFuenteConcluida p {
  background-color: rgba(139, 113, 29, 0.6);
}
.directasFuenteConcluida p:hover {
  background-color: rgba(139, 113, 29);
}

.directasFuenteTemporal p {
  background-color: rgba(218, 218, 218, 0.6);
}
.directasFuenteTemporal p:hover {
  background-color: rgb(218, 218, 218);
}

.airbnbFuente p {
  background-color: rgba(245, 179, 179, 0.6);
}

.airbnbFuente p:hover {
  background-color: rgb(245, 179, 179);
}

.fechaSobrereserva p{
  background-color: rgba(252, 228, 150, 0.8);
  font-weight: 700;
  min-height: 50%;
}

.fechaSobrereserva p:hover{
  background-color: rgb(252, 228, 150);
}

.fechaDeEntrada p {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.fechaDeSalida p {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.filaDeDias {
  display: flex;
  width: 100%;
}

.calendarioCabana {
  display: flex;
  width: 100%;
}

.divNombreCalendarioCabana {
  width: 12.5%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: gray solid 1px;
}

#calendarios .divVacio {
  width: 12.5%;
  height: 60px;
  border: rgba(128, 128, 128, 0) solid 1px;
}

.divNombreCalendarioCabana p {
  font-size: 24px;
  font-weight: 700;
}

.fechaSeleccionDinamica .background {
  background-color: rgba(220, 239, 255, 0.7);
}

.fechaSeleccionada .background {
  background-color: rgba(166, 212, 255, 0.7);
}

.diaDeCalendario .background:hover {
  background-color: rgba(220, 239, 255, 0.7);
}

.diaDeCalendario .indicadores {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(80, 80, 80);
}

.diaDeCalendario .minimoDeNoches {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
}

@media screen and (max-width: 1250px) {
  .divNombreCalendarioCabana p {
    font-size: 20px;
    font-weight: 700;
  }
}

@media screen and (max-width: 1000px) {
  .divNombreCalendarioCabana p {
    font-size: 16px;
  }
}

@media screen and (max-width: 650px) {
  .divNombreCalendarioCabana p {
    font-size: 12px;
    font-weight: 600;
    transform: rotate(270deg);
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .divNombreCalendarioCabana p {
    font-size: 10px;
  }
}

@media screen and (max-width: 380px) {
  .divNombreCalendarioCabana p {
    font-size: 9px;
  }
}

@media screen and (max-height: 450px) {
  .divBotonesVisualizacionFechas {
    width: 90%;
  }
}
