.panel-finanzas {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.panel-finanzas .total {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 12px 50px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 14px 0px grey;
}

.panel-finanzas .total .balance {
  color: rgb(28, 145, 28);
  font-size: 35px;
  font-weight: 700;
}

.panel-finanzas .cuentas {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.panel-finanzas .cuentas .cuenta {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 200px;
  height: 200px;
  margin: 10px;
  border-radius: 100px;
  box-shadow: 0px 0px 14px 0px grey;
  cursor: pointer;
  transition: all 0.3s;
}

.panel-finanzas .cuentas .cuenta:hover {
  width: 210px;
  height: 210px;
  border-radius: 105px;
  box-shadow: 0px 0px 10px 0px rgb(155, 155, 155);
}

.panel-finanzas .cuentas .cuenta .nombre,
.panel-finanzas .total .nombre {
  font-size: 18px;
  font-weight: 500;
}

.panel-finanzas .cuentas .cuenta .balance {
  font-size: 25px;
  font-weight: 700;
}

.panel-finanzas .botones-ingresos-egresos {
  display: flex;
}

@media screen and (max-width: 950px) {
  .panel-finanzas {
    margin-bottom: 170px;
  }

  .panel-finanzas .botones-ingresos-egresos {
    position: fixed;
    bottom: 0px;
  }
}
