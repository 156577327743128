.panel-reservaciones {
  padding: 16px;
  max-height: 100vh;
  width: 100%;
}

.div-tabla-reservaciones {
  height: calc(100% - 46px);
}

.div-titulo-navegacion-reservaciones {
  display: flex;
}

.navegacion-reservaciones {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 20px;
  margin-bottom: 16px;
}

.navegacion-reservaciones p {
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 20px;
  transition: all 0.3s;
}

.navegacion-reservaciones p.activa {
  text-decoration: underline;
  color: #eb7d00;
  cursor: auto;
}

.navegacion-reservaciones p.inactiva {
  color: #663700;
}

.navegacion-reservaciones p.inactiva:hover {
  color: #f78400;
}

.botones-columna-acciones-reservaciones,
.botones-columna-acciones-reservaciones-inactivos {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #00731d;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s;
}

.botones-columna-acciones-reservaciones-inactivos {
  color: #b9b9b9;
}

.botones-columna-acciones-reservaciones:hover,
.botones-columna-acciones-reservaciones-inactivos:hover {
  color: rgb(136, 109, 0);
}

.boton-filtro-qr {
  display: flex;
  align-items: center;
  height: 34px;
  background-color: rgb(0, 115, 29);
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 8px 12px;
  font-size: 18px;
}

.animacion-cambio-tabla-reservaciones {
  animation-name: entrada-tabla-reservaciones;
  animation-duration: 500ms;
  animation-iteration-count: 2;
  animation-direction: alternate;
}

@keyframes entrada-tabla-reservaciones {
  from {
    margin-left: 0%;
  }
  to {
    margin-left: 100%;
  }
}
