.panel-estadisticas {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.panel-estadisticas .top-20-fechas {
  padding: 20px;
}

.panel-estadisticas .confirmaciones-fechas {
  padding: 20px;
}
