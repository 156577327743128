.App {
  text-align: center;
  display: flex;
}

.router {
  width: calc(100% - 222px);
  overflow: auto;
  box-sizing: border-box;
  background-color: rgba(240, 194, 0, 0.05);
  display: flex;
  flex-flow: column;
}

.router .titulo-pagina {
  text-align: start;
  font-size: 22px;
  font-weight: 600;
  color: #6b3900;
  padding-bottom: 16px;
}

/* Sin flechitas Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*Sin flechitas Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 950px) {
  .router .titulo-pagina {
    padding-left: 30px;
  }
}
