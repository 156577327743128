#login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../media/imagenes/background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#login form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: rgba(219, 186, 174, 0.274);
  padding: 16px;
  border-radius: 8px;
}

#login form img{
    width: 300px;
}

#login form p{
    margin-bottom: 30px;
    font-size: 20px;
}

#login form input {
  border: none;
  color: rgb(53, 14, 0);
  border-bottom: rgb(53, 14, 0) solid 3px;
  background-color: #ffffff00;
  margin: 20px 0;
  transition: all 0.6s;
  font-size: 20px;
  min-width: 300px;
}

#login form input:focus {
  color: rgb(136, 109, 0);
  border-bottom: rgb(136, 109, 0) solid 3px;
}

#login form button {
  border: none;
  border-radius: 10px;
  background-color: rgb(0, 71, 18);
  color: white;
  font-size: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s;
}

#login form button:hover {
  background-color: rgb(136, 109, 0);
}

#login form button:active {
  background-color: rgb(0, 71, 18);
}
