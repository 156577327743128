.boton-nuevo-ingreso {
  background-color: rgb(0, 71, 18);
  width: 150px;
  height: 150px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border-radius: 75px;
  font-size: 20px;
  box-shadow: 0px 0px 10px 2px rgb(155 155 155);
  cursor: pointer;
  transition: all 0.3s;
}

.boton-nuevo-ingreso:hover {
  background-color: rgb(0, 99, 25);
}

.boton-nuevo-ingreso p {
  color: white;
}

.boton-nuevo-ingreso svg {
  font-size: 50px;
  color: white;
}
