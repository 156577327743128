.nuevo-cupon {
  background-color: rgba(255, 255, 255, 0.466);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0px #b9b9b9;
  overflow: auto;
  max-height: 80vh;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.nuevo-cupon .coleccion-div select{
 padding: 4px;
 font-size: 18px;
 font-weight: 500;
 color: dimgray;
 text-align: center;
 background-color: transparent;
 border: none;
}

.nuevo-cupon .selector-tipo-div {
  display: grid;
  grid-template-areas:
    "p p"
    "o d";
  margin: 5px;
}

.nuevo-cupon .selector-tipo-div h3 {
  grid-area: p;
  margin-bottom: 10px;
}

.nuevo-cupon .selector-tipo-div .boton-porcentaje {
  grid-area: o;
}

.nuevo-cupon .selector-tipo-div .boton-dinero {
  grid-area: d;
}

.nuevo-cupon .selector-tipo-div .boton-porcentaje,
.nuevo-cupon .selector-tipo-div .boton-dinero {
  background-color: #dbdbdb;
  padding: 12px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 8px;
  width: 100px;
  transition: all 0.5s;
  cursor: pointer;
}

.nuevo-cupon .selector-tipo-div .no-seleccionado:hover {
  background-color: #b9b9b9;
}

.nuevo-cupon .selector-tipo-div .seleccionado {
  background-color: rgb(0, 71, 18);
  color: white;
}

.nuevo-cupon .valor-div {
  margin: 5px;
}

.nuevo-cupon .valor-div input {
  border: none;
  border-bottom: 2px solid black;
  padding: 5px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.nuevo-cupon .fechas-div {
  margin: 5px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.nuevo-cupon .fechas-div h3 {
  width: 100%;
  text-align: center;
}

.nuevo-cupon .fechas-div label {
  font-size: 18px;
}

.nuevo-cupon .fechas-div input {
  border: none;
  border-bottom: 2px solid black;
  padding: 5px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.nuevo-cupon .botones-div {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.nuevo-cupon .botones-div .boton-cancelar,
.nuevo-cupon .botones-div .boton-accion {
  background-color: rgb(0, 71, 18);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  font-size: 18px;
  transition: all 0.4s;
  cursor: pointer;
  width: 110px;
}

.nuevo-cupon .botones-div .boton-cancelar:hover,
.nuevo-cupon .botones-div .boton-accion:hover {
  background-color: rgb(136, 109, 0);
}

.nuevo-cupon .botones-div .boton-cancelar:active,
.nuevo-cupon .botones-div .boton-accion:active {
  background-color: rgb(0, 71, 18);
}

.nuevo-cupon .botones-div .boton-accion:disabled {
    background-color: rgb(141, 141, 141);
  }
