#visualizador-sobrereserva {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.466);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0px #b9b9b9;
  overflow: hidden;
  max-height: 95vh;
  max-width: 95vw;
}

#visualizador-sobrereserva .div-reservaciones-visualizador-sobrereserva {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
}

#visualizador-sobrereserva
  .div-reservaciones-visualizador-sobrereserva
  .reservacion-visualizador-sobrereserva {
  padding: 16px;
  min-width: 300px;
}

#visualizador-sobrereserva
  .div-reservaciones-visualizador-sobrereserva
  .encabezado.confirmada
  h2 {
  color: rgb(0, 99, 25);
}

#visualizador-sobrereserva
  .div-reservaciones-visualizador-sobrereserva
  .encabezado.cancelada
  h2 {
  color: rgb(116, 0, 0);
}


#visualizador-sobrereserva .boton-cerrar {
  background-color: var(--colores-principales-cima-verde);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.4s;
}

#visualizador-sobrereserva .boton-cerrar:hover {
  background-color: var(--colores-principales-cima-amarillo);
}

#visualizadorDeReservacion .boton-cerrar:active {
  background-color: var(--colores-principales-cima-verde);
}
