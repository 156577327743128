.panel-finanzas-desglose-movimientos {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 40px 0px 170px 0px;
}

.panel-finanzas-desglose-movimientos .regresar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 16px;
  font-size: 20px;
  font-weight: 600;
  color: gray;
  transition: all 0.3s;
  cursor: pointer;
}

.panel-finanzas-desglose-movimientos .regresar:hover {
  color: rgb(0, 115, 29);
  text-decoration: underline;
}

.panel-finanzas-desglose-movimientos .cuenta-movimientos {
  display: grid;
  max-width: 100%;
  grid-template-columns: 50% 50%;
  grid-template-areas:
    "b b"
    "f f"
    "i e";
}

.panel-finanzas-desglose-movimientos .cuenta-movimientos .nombre-balance {
  grid-area: b;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0px 0px 13px -4px darkgrey;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  margin: 5px;
}

.panel-finanzas-desglose-movimientos .cuenta-movimientos .fecha {
  grid-area: f;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 35px;
  color: gray;
}

.panel-finanzas-desglose-movimientos .cuenta-movimientos .fecha .mes-anio {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.panel-finanzas-desglose-movimientos
  .cuenta-movimientos
  .fecha
  .mes-anio
  select {
  background-color: white;
  padding: 5px;
  margin: 5px;
  box-sizing: border-box;
  box-shadow: 0px 0px 13px -4px darkgrey;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  border: none;
}
.panel-finanzas-desglose-movimientos
  .cuenta-movimientos
  .fecha
  .mes-anio
  input {
  background-color: white;
  padding: 5px;
  margin: 5px;
  box-sizing: border-box;
  box-shadow: 0px 0px 13px -4px darkgrey;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  border: none;
}

.panel-finanzas-desglose-movimientos .cuenta-movimientos .fecha svg {
  cursor: pointer;
  transition: all 0.3s;
}

.panel-finanzas-desglose-movimientos .cuenta-movimientos .fecha svg:hover {
  color: rgb(0, 115, 29);
}

.panel-finanzas-desglose-movimientos .cuenta-movimientos .ingresos {
  grid-area: i;
  margin: 5px;
  height: 60vh;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 0px 13px -4px darkgrey;
  border-radius: 5px;
}

.panel-finanzas-desglose-movimientos .cuenta-movimientos .egresos {
  grid-area: e;
  margin: 5px;
  height: 60vh;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 0px 13px -4px darkgrey;
  border-radius: 5px;
}

.panel-finanzas-desglose-movimientos
  .cuenta-movimientos
  .ingresos
  .contenedor-general-tabla-dinamica,
.panel-finanzas-desglose-movimientos
  .cuenta-movimientos
  .egresos
  .contenedor-general-tabla-dinamica {
  background-color: transparent;
  box-shadow: none;
  height: 95%;
}

.panel-finanzas-desglose-movimientos .cuenta-movimientos .ingresos p,
.panel-finanzas-desglose-movimientos .cuenta-movimientos .egresos p {
  font-size: 18px;
  font-weight: 700;
}

.panel-finanzas-desglose-movimientos .botones-ingresos-egresos {
  position: fixed;
  bottom: 0px;
  right: 0px;
  display: flex;
}

@media screen and (max-width: 950px) {
  .panel-finanzas-desglose-movimientos .cuenta-movimientos {
    display: flex;
    flex-flow: column;
    max-width: 100%;
  }

  .panel-finanzas-desglose-movimientos .botones-ingresos-egresos {
    width: 100%;
    justify-content: center;
  }
}
