#modal,
#cierra-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: modal;
  animation-duration: 500ms;
}

#cierra-modal {
  animation-name: cierra-modal;
  animation-duration: 500ms;
}

@keyframes modal {
  from {
    backdrop-filter: none;
    opacity: 0;
  }
  to {
    backdrop-filter: blur(10px);
    opacity: 1;
  }
}

@keyframes cierra-modal {
  from {
    backdrop-filter: blur(10px);
    opacity: 1;
  }
  to {
    backdrop-filter: none;
    opacity: 0;
    display: none;
  }
}
