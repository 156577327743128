body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100vw;
  min-height: 100vh;
}

body::-webkit-scrollbar{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
}

*:focus {
  outline: none;
}



:root{
  --colores-principales-cima-cafe: rgb(102, 55, 0);
  --colores-principales-cima-amarillo: rgb(136, 109, 0);
  --colores-principales-cima-verde: rgb(0, 71, 18);
  --colores-principales-cima-marron: rgb(53, 14, 0);
}
